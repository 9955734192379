*{
    margin: 0;
    padding: 0;
}
ul{
    padding: 0;
    margin: 0;
}
li{
    list-style: none;
}
body{
    background-color: #eee;
    font-family: 'Open Sans', sans-serif;
}


  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
    display: none;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #3c3737; 
    border-radius: 10px;
    display: none;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #3c3737; 
    display: block;
  }


.side-menu{
    position: fixed;
    background: #3c3737;
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 20px;
    transition: width .2s ease-in;
}

.side-menu.inactive{
    width: 80px;
}

.side-menu .top-section{
    position: relative;
}

.side-menu .top-section .logo{
    width: 100px;
    height: 40px;
    overflow: hidden;
    display: inline-block;
}
.side-menu .top-section .logo img{
    max-width: 100%;
    max-height: 100%;
}

.side-menu .top-section .toggle-menu-btn{
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    cursor: pointer;
}
.control-label .validation-required {
    color: #e3094c;
    vertical-align: baseline;
    position: relative;
    top: -4px;
  }
  .control-label {
    font-family: open_sansbold,system-ui,-apple-system,"Segoe UI",sans-serif;
    font-weight: 700;
    font-size: 1rem;
}
.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.675rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
}
.custom-checkbox .custom-control-label {
    color: inherit;
    font-size: 1rem;
    cursor: pointer;
    padding: 0 0 6px 4px;
    margin-bottom: 0;
}
.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.175rem;
    height: 1.3375rem;
}
.side-menu.inactive .top-section .toggle-menu-btn{
    right: -50px;
}

.side-menu .search-controller{
    color: #fff;
    position: relative;
}

.side-menu .search-controller .search-btn{
    width: 40px;
    height: 35px;
    position: absolute;
    background: transparent;
    border: 0;
    font-size: 20px;
    color: #666;
}

.side-menu .search-controller input[type=text]{
    border: 0;
    outline: none;
    height: 35px;
    background: #333;
    border-radius: 5px;
    display: block;
    margin: 20px 0;
    width: 100%;
    box-sizing: border-box;
    padding-left: 40px;
    color: #666;
}

.side-menu .main-menu .menu-item{
    color: #fff;
    text-decoration: none;
    font-size: 15px;
    display: block;
    font-weight: 600;
    cursor: pointer;
}

.side-menu .main-menu .menu-item.active{
    background: #3c3737;
    border-radius: 5px;
    color: #fff;
}


.side-menu .main-menu{
    margin: 5px -21px;
    position: relative;
    max-height: 250px;
    overflow-x: hidden;
}

.headermenu {
    width: 100%;
    position: absolute;
    background: #5f5b5b;
    height: 8vh;
    float: right;
}

.side-menu .main-menu .menu-item .menu-icon{
    display: inline-block;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
}

.side-menu .main-menu .menu-item span{
    position: absolute;
    display: inline-block;
    line-height: 40px;
    opacity: 1;
    transition: opacity .2s ease-in;
    font-family: 'open_sansregular';
    font-size: 17px;
}

.side-menu.inactive .main-menu .menu-item span{
    opacity: 0;
    width: 0;
    height: 0;
    overflow: hidden;
}

.side-menu .main-menu .sub-menu{
    color: #333;
    margin-left: 20px;
    border-left: 1px solid #666;
    box-sizing: border-box;
    padding-left: 30px;
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-in;
}



.side-menu .main-menu .sub-menu.active{
    max-height: 200px;
}

.side-menu .main-menu .sub-menu a{
    display: block;
    margin: 5px 0;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #333;
    box-sizing: border-box;
    padding: 5px;
}


.side-menu .main-menu .sub-menu a.active{
    /* change in background, not recorded in video */
    background: #222;
    display: block;
    
    border-radius: 5px;
    color: #fff;

}


.divider{
    width: 100%;
    height: 1px;
    border-radius: 1px;
    background: #fff;
    margin-bottom: 28px;
    margin-top: 15px;
}

._container{
    margin-left: 230px;
    transition: margin-left .2s ease-in;
    padding: 72px;
    box-sizing: border-box;
}

._container.inactive{
    margin-left: 80px;
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color: black !important;
    padding-right: calc(1.8em + 0.668rem) !important;
    background-image: none !important;
    background-repeat: no-repeat !important;
    background-position: right calc(0.45em + 0.367rem) center !important;
    background-size: calc(0.9em + 0.334rem) calc(0.9em + 0.334rem) !important;
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus { 
    border: 1px solid #333 !important;
    box-shadow: none !important;
}

.form-control:focus, .form-control:active {
    border: 1px solid #929090 !important;
    box-shadow: 0 0 0 1px #929090 !important;
    outline: 0;
}
.form-control:focus {
    color: #333 !important;
    background-color: #fff !important;
    border-color: #9f9b9b !important;
    outline: 0 !important;
    box-shadow: none !important;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #dc3545 !important;
    background-image: none !important;
}